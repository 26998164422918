<!--表单控件组件-->
<template>
  <div id="superForm" class="clear" v-if="show">  
    <template v-for="list in formList"> 
      <!--原有控件-->
      <!-- text -->
      <el-form-item v-if="list.type ==='text' && list.showhide && !list.inputWidth" :class="{bitian:list.requireds,'form_list':true, 'input_t':true,[list.labelClass]:true,'myReadonly':list.readonly}"
                    :prop="list.name" :label-width="list.labelWidth" :style="{width: list.width,maxWidth:'100%'}">
        <span slot="label">
          <el-popover
            v-if="list.iconShowLeft"
            placement="top"
            title="说明"
            width="400"
            trigger="hover"
            :content="list.title">
            <i class="iconfont f-s-18" @click.prevent="ceshi(list)" :class="list.iconName" slot="reference"></i>
          </el-popover>
          {{list.label}}
        </span>
        <span class="iconClicks" @click.prevent="ceshi(list)" v-if="list.iconShow">
          <i class="iconfont f-s-18 iconstyle" :class="list.iconName"></i>
        </span>
        <el-input :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize"
                  :name="list.name"
                  style="width: 100%;"></el-input>
      </el-form-item>
      <el-form-item v-if="list.type ==='text' && list.showhide && list.inputWidth" :class="{bitian:list.requireds,'form_list':true, 'input_t':true,'myReadonly':list.readonly}"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <span slot="label">
          <el-popover
            v-if="list.iconShowLeft"
            placement="top"
            title="说明"
            width="400"
            trigger="hover"
            :content="list.title">
            <i class="iconfont f-s-18" @click.prevent="ceshi(list)" :class="list.iconName" slot="reference"></i>
          </el-popover>
          {{list.label}}
        </span>
        <span class="iconClicks" @click.prevent="ceshi(list)" v-if="list.iconShow">
          <i class="iconfont f-s-18 iconstyle" :class="list.iconName"></i>
        </span>
        <el-input :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize"
                  :name="list.name"
                  :style="'width: '+list.inputWidth"></el-input>
      </el-form-item>
      <!--text前面带文字-->
      <el-form-item v-if="list.type ==='texttxtprepend' && list.showhide" class="form_list input_t" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-input :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize" :name="list.name"
                  style="width: 100%;">
          <template slot="prepend">{{list.prependtxt}}</template>
        </el-input>
      </el-form-item>
      <el-form-item v-if="list.type ==='colorPicker' && list.showhide" class="form_list input_t" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
          <el-color-picker v-model="formInline[list.name]" size="mini"></el-color-picker>
        </el-input>
      </el-form-item>
      <!--text后面带文字-->
      <el-form-item v-if="list.type ==='texttxtappend' && list.showhide" class="form_list input_t" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-input :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize"
                  style="width: 100%;">
          <template slot="append">{{list.appendtxt}}</template>
        </el-input>
      </el-form-item>
      <!--text前后带文字-->
      <el-form-item v-if="list.type ==='texttxt' && list.showhide" class="form_list input_t" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-input :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize"
                  style="width: 100%;" :name="list.name">
          <template slot="prepend">{{list.prependtxt}}</template>
          <template slot="append">{{list.appendtxt}}</template>
        </el-input>
      </el-form-item>
      <!--text前面带按钮-->
      <el-form-item v-if="list.type ==='textbtnprepend' && list.showhide" class="form_list input_t" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-input :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize"
                  style="width: 100%;">
          <el-button slot="prepend" @click="textBtnClick(formInline[list.name],list.name)">
            <i class="iconfont" :class="list.prependbtnicon"></i>
            {{list.prependbtntxt}}
          </el-button>
        </el-input>
      </el-form-item>
      <!--text后面带按钮-->
      <el-form-item v-if="list.type ==='textbtnappend' && list.showhide" :class="{bitian:list.requireds,'form_list':true, 'input_t':true}" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-input :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize"
                  style="width: 100%;">
          <el-button slot="append" @click="textBtnClick(formInline[list.name],list.name)">
            <i class="iconfont" :class="list.appendbtnicon"></i>
            {{list.appendbtntxt}}
          </el-button>
        </el-input>
      </el-form-item>
      <!--text前面文字后面按钮-->
      <el-form-item v-if="list.type ==='texttxtbtn' && list.showhide" class="form_list input_t" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-input :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize"
                  style="width: 100%;">
          <template slot="prepend">{{list.prependtxt}}</template>
          <el-button slot="append" @click="textBtnClick(formInline[list.name],list.name)">
            <i class="iconfont" :class="list.appendbtnicon"></i>
            {{list.appendbtntxt}}
          </el-button>
        </el-input>
      </el-form-item>
      <!--text前面按钮后面按钮-->
      <el-form-item v-if="list.type ==='textbtnbtn' && list.showhide" class="form_list input_t" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-input :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize"
                  style="width: 100%;">
          <el-button slot="prepend" @click="textBtnClick(formInline[list.name],list.name,list.prependbtntxt)">
            <i class="iconfont" :class="list.prependbtnicon"></i>
            {{list.prependbtntxt}}
          </el-button>
          <el-button slot="append" @click="textBtnClick(formInline[list.name],list.name,list.appendbtntxt)">
            <i class="iconfont" :class="list.appendbtnicon"></i>
            {{list.appendbtntxt}}
          </el-button>
        </el-input>
      </el-form-item>
       <!-- text后面上传附件 -->
      <el-form-item v-if="list.type ==='textUpload' && list.showhide && !list.isNumber" :class="{'input_t':true,'form_list':true,'myReadonly':list.readonly}"  :label="list.label"
                    :prop="list.name" :label-width="list.labelWidth"  :style="{width: list.width,maxWidth:'100%'}">
        <el-input :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize"
                  :name="list.name"
                  style="width: 85%;"></el-input>
                  <el-popover
                  v-if="list.showTips"
                    placement="top-start"
                    title="提示"
                    width="200"
                    trigger="hover"
                    :content="list.showText">
                      <span class="uploadBtn notUploadBtn" @click="uploadClick(list)"  slot="reference">
                      <i class="iconfont icon-fujian" style="margin-right:5px"></i>附件
                    </span>
                  </el-popover>
                  <el-popover
                    v-else
                    placement="top-start"
                    title="提示"
                    width="200"
                    trigger="hover"
                    content="点击上传证明材料">
                      <span class="uploadBtn allowUploadBtn" @click="uploadClick(list)" slot="reference">
                        <i class="iconfont icon-fujian" style="margin-right:5px"></i>附件
                      </span>
                  </el-popover>
      </el-form-item>
       <!-- text后面上传附件 -->
      <el-form-item v-if="list.type ==='textUpload' && list.showhide && list.isNumber" :class="{'input_t':true,'form_list':true,'myReadonly':list.readonly}" :label="list.label"
                    :prop="list.name" :label-width="list.labelWidth" :style="{width: list.width,maxWidth:'100%'}">
        <span slot="label">
          <el-popover
            v-if="list.iconShowLeft"
            placement="top"
            title="说明"
            width="400"
            trigger="hover"
            :content="list.title">
            <i class="iconfont f-s-18" @click.prevent="ceshi(list)" :class="list.iconName" slot="reference"></i>
          </el-popover>
          {{list.label}}
        </span>
        <span class="iconClicks" @click.prevent="ceshi(list)" v-if="list.iconShow">
          <i class="iconfont f-s-18 iconstyle" :class="list.iconName"></i>
        </span>
        <el-input type="number" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max || 999999999" :min="list.min || -9999999999" :auto-complete="list.autocomplete" :step="list.step || 0.0000001"
                  :autofocus="list.autofocus" :resize="list.resize"
                  :name="list.name" @blur="inputBlur(list.name,formInline[list.name],formInline)"
                  :class="{'delInput':true,'myReadonly':list.readonly}"
                  style="width: 85%;"></el-input>
          <el-popover
            v-if="list.showTips"
            placement="top-start"
            title="提示"
            width="200"
            trigger="hover"
            :content="list.showText">
              <span class="uploadBtn notUploadBtn" @click="uploadClick(list)"  slot="reference">
              <i class="iconfont icon-fujian" style="margin-right:5px"></i>附件
            </span>
          </el-popover>
          <el-popover
              v-else
              placement="top-start"
              title="提示"
              width="200"
              trigger="hover"
              content="点击上传证明材料">
                <span class="uploadBtn allowUploadBtn" @click="uploadClick(list)" slot="reference"> 
                  <i class="iconfont icon-fujian" style="margin-right:5px"></i>附件
                </span>
            </el-popover>
      </el-form-item>
      <!-- 分段 --> 
      <el-form-item v-if="list.type === 'paragraph' && list.showhide" class="paragraph form_list input_t" 
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}" :label-width="list.labelWidth || '50px'">
            <span class="paragraph_box" >
              <i class="paragraph_icon"></i>
              <!-- <span class="paragraph_label">{{list.label}}</span> -->
              <span slot="label" class="paragraph_label">
                {{list.label}}
                <el-popover
                  v-if="list.iconShowRightDuan"
                  placement="top"
                  title="说明"
                  width="400"
                  trigger="hover"
                  :content="list.title">
                  <i class="iconfont f-s-18" @click.prevent="ceshi(list)" :class="list.iconName" slot="reference"></i>
                </el-popover>
              </span>
              <span class="iconClicks" @click.prevent="ceshi(list)" v-if="list.iconShow">
                <i class="iconfont f-s-18 iconstyle" :class="list.iconName"></i>
              </span>
              <span style="margin-left:30px" v-if="list.showUpload">
                <span>{{list.showUploadText}}</span>
                <el-popover
                  v-if="list.showTips"
                    placement="top-start"
                    title="提示"
                    width="200"
                    trigger="hover"
                    :content="list.showText">
                    <!-- @click="uploadClick(list)" -->
                      <span class="uploadBtn notUploadBtn"   slot="reference">
                      <i class="iconfont icon-fujian" style="margin-right:5px"></i>附件
                    </span>
                </el-popover>
                <el-popover
                  v-else
                  placement="top-start"
                  title="提示"
                  width="200"
                  trigger="hover"
                  content="点击上传证明材料">
                    <span class="uploadBtn allowUploadBtn" @click="uploadClick(list)" slot="reference">
                      <i class="iconfont icon-fujian" style="margin-right:5px"></i>附件
                    </span>
                </el-popover>
              </span>
              <span slot="label" style="margin-left:5px">
                <el-popover
                  v-if="list.iconShowRight"
                  placement="top"
                  title="说明"
                  width="400"
                  trigger="hover"
                  :content="list.title">
                  <i class="iconfont f-s-18" @click.prevent="ceshi(list)" :class="list.iconName" slot="reference"></i>
                </el-popover>
              </span>
            </span>
      </el-form-item>
      <el-form-item v-if="list.type === 'illustrate' && list.showhide" class="paragraph form_list input_t" 
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}" :label-width="list.labelWidth || '50px'">
            <span style="color:red;display:inline-block;min-width:100px" class="t-a-r">{{list.illustrateName}}</span><span v-html="list.illustrateTxt"></span>
      </el-form-item>
      <!--输入后自动匹配-->
      <el-form-item v-if="list.type === 'autocomplete' && list.showhide" class="form_list input_t" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-autocomplete :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                         :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                         :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                         :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                         :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                         :autofocus="list.autofocus" :resize="list.resize" :fetch-suggestions="querySearch"
                         :trigger-on-focus="false" @select="handleSelect"
                         style="width: 100%;"></el-autocomplete>
      </el-form-item>
      <!-- number -->
      <el-form-item v-if="list.type==='number' && list.showhide && !list.appendtxt" :class="{'input_t':true,'form_list':true,'myReadonly':list.readonly}" :label="list.label"
                    :prop="list.name" :label-width="list.labelWidth" :style="{width: list.width,maxWidth:'100%'}">
        <span slot="label">
          <el-popover
            v-if="list.iconShowLeft"
            placement="top"
            title="说明"
            width="400"
            trigger="hover"
            :content="list.title">
            <i class="iconfont f-s-18" @click.prevent="ceshi(list)" :class="list.iconName" slot="reference"></i>
          </el-popover>
          {{list.label}}
        </span>
        <span class="iconClicks" @click.prevent="ceshi(list)" v-if="list.iconShow">
          <i class="iconfont f-s-18 iconstyle" :class="list.iconName"></i>
        </span>
        <el-input v-if="list.inputWidth" :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max || 9999999999" :min="list.min || -999999999" :auto-complete="list.autocomplete" :step="list.step || 0.0000001"
                  :autofocus="list.autofocus" :resize="list.resize" @blur="inputBlur(list.name,formInline[list.name],formInline)"
                  :name="list.name" class="delInput"
                  :style="'width: '+list.inputWidth"></el-input>
        <el-input v-else :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max || 9999999999" :min="list.min || -999999999" :auto-complete="list.autocomplete" :step="list.step || 0.0000001"
                  :autofocus="list.autofocus" :resize="list.resize" @blur="inputBlur(list.name,formInline[list.name],formInline)"
                  :name="list.name" class="delInput"
                  style="width:100%"></el-input>
      </el-form-item>
      <el-form-item v-if="list.type==='number' && list.showhide && list.appendtxt" :class="{'input_t':true,'form_list':true,'myReadonly':list.readonly}" :label="list.label"
                    :prop="list.name" :label-width="list.labelWidth" :style="{width: list.width,maxWidth:'100%'}">
        <el-input v-if="list.inputWidth" :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max || 9999999999" :min="list.min || -999999999" :auto-complete="list.autocomplete" :step="list.step || 0.0000001"
                  :autofocus="list.autofocus" :resize="list.resize"
                  :name="list.name" class="delInput"
                  :style="'width: '+list.inputWidth"></el-input>
        <el-input v-else :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max || 9999999999" :min="list.min || -999999999" :auto-complete="list.autocomplete" :step="list.step || 0.0000001"
                  :autofocus="list.autofocus" :resize="list.resize"
                  :name="list.name" class="delInput"
                  style="width:90%"></el-input>
        <span>{{list.appendtxt}}</span>
      </el-form-item>
      <!-- password -->
      <el-form-item v-if="list.type==='password' && list.showhide" class="form_list input_t" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}"
                    :required="list.required">
                    <!-- :suffix-icon="list.suffixicon" -->
        <el-input :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon"  :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize" :name="list.name"
                  style="width:100%">
                      <i v-if="list.suffixicon" slot="suffix" :class="['el-input__icon',list.suffixicon]"
                       @click="list.callBack(list.name)"></i>
                  </el-input>
      </el-form-item>
      <!-- textarea -->
      <el-form-item v-if="list.type==='textarea' && list.showhide" class="form_list" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <span slot="label">
          <el-popover
            v-if="list.iconShowLeft"
            placement="top"
            title="说明"
            width="400"
            trigger="hover"
            :content="list.title">
            <i class="iconfont f-s-18" @click.prevent="ceshi(list)" :class="list.iconName" slot="reference"></i>
          </el-popover>
          {{list.label}}
        </span>
        <span class="iconClicks" @click.prevent="ceshi(list)" v-if="list.iconShow">
          <i class="iconfont f-s-18 iconstyle" :class="list.iconName"></i>
        </span>
        <el-input :type="list.type" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize"
                  style="width:100%"></el-input>
      </el-form-item>
      <!-- textarea后面上传附件 -->
      <el-form-item v-if="list.type ==='textareaUpload' && list.showhide" class="form_list input_t"  :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-input type="textarea" v-model="formInline[list.name]" :placeholder="list.placeholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize"
                  style="width:85%"></el-input>
          <el-popover
            v-if="list.showTips"
            placement="top-start"
            title="提示"
            width="200"
            trigger="hover"
            :content="list.showText">
            <!-- @click="uploadClick(list)" -->
              <span class="uploadBtn notUploadBtn"   slot="reference">
              <i class="iconfont icon-fujian" style="margin-right:5px"></i>附件
            </span>
          </el-popover>
          <el-popover
              v-else
              placement="top-start"
              title="提示"
              width="200"
              trigger="hover"
              :content="list.content">
                <span class="uploadBtn allowUploadBtn" @click="uploadClick(list)" slot="reference"> 
                  <i class="iconfont icon-fujian" style="margin-right:5px"></i>附件
                </span>
            </el-popover>
      </el-form-item>
      <!--选择器-->
      <el-form-item v-if="list.type==='select' && list.showhide" class="form_list select_list" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-select v-model="formInline[list.name]" :placeholder="list.placeholder" :disabled="list.disabled"
                   :multiple="list.multiple" :clearable="list.clearable" :filterable="list.filterable"
                   :collapse-tags="list.collapsetags" :multiple-limit="list.multiplelimit"
                   :allow-create="list.allowcreate" :popper-append-to-body="list.popperappendtobody"
                   style="width:100%" @change="selectChange(arguments[0],list.name,list.option,formInline)"
                   @visible-change="visibleChange">
          <el-option v-for="item in list.option" :key="item.value" :label="list.labelName ? item[list.labelName] : item.labelname" 
          :value="list.labelCode ? item[list.labelCode] : item.value"
                     :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-form-item>
      <!--选择器-->
      <el-form-item v-if="list.type==='selectst' && list.showhide &&!list.inputWidth" :class="{bitian:list.requireds,'form_list':true,[list.labelClass]:true,'myDisabled':list.disabled}" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <span slot="label">
          <el-popover
            v-if="list.iconShowLeft"
            placement="top"
            title="说明"
            width="400"
            trigger="hover"
            :content="list.title">
            <i class="iconfont f-s-18" @click.prevent="ceshi(list)" :class="list.iconName" slot="reference"></i>
          </el-popover>
          {{list.label}}
        </span>
        <span class="iconClicks" @click.prevent="ceshi(list)" v-if="list.iconShow">
          <i class="iconfont f-s-18 iconstyle" :class="list.iconName"></i>
        </span>
        <el-select v-model="formInline[list.name]" :placeholder="list.placeholder" :disabled="list.disabled"
                   :multiple="list.multiple" :clearable="list.clearable" :filterable="list.filterable"
                   :collapse-tags="list.collapsetags" :multiple-limit="list.multiplelimit"
                   :allow-create="list.allowcreate" :popper-append-to-body="list.popperappendtobody"
                   style="width:100%" @change="selectChange(arguments[0],list.name,list.option,formInline)"
                   @visible-change="visibleChange" :filter-method="list.filterMethodChange">
          <el-option v-for="item in list.option" :key="item.value" 
          :label="list.labelName ? item[list.labelName] : item.labelname" 
          :value="list.labelCode ? item[list.labelCode] : item.value"
                     :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="list.type==='selectst' && list.showhide &&list.inputWidth" :class="{bitian:list.requireds,'form_list':true,'myDisabled':list.disabled}" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <span slot="label">
          <el-popover
            v-if="list.iconShowLeft"
            placement="top"
            title="说明"
            width="400"
            trigger="hover"
            :content="list.title">
            <i class="iconfont f-s-18" @click.prevent="ceshi(list)" :class="list.iconName" slot="reference"></i>
          </el-popover>
          {{list.label}}
        </span>
        <span class="iconClicks" @click.prevent="ceshi(list)" v-if="list.iconShow">
          <i class="iconfont f-s-18 iconstyle" :class="list.iconName"></i>
        </span>
        <el-select v-model="formInline[list.name]" :placeholder="list.placeholder" :disabled="list.disabled"
                   :multiple="list.multiple" :clearable="list.clearable" :filterable="list.filterable"
                   :collapse-tags="list.collapsetags" :multiple-limit="list.multiplelimit"
                   :allow-create="list.allowcreate" :popper-append-to-body="list.popperappendtobody"
                   :style="'width: '+list.inputWidth" @change="selectChange(arguments[0],list.name,list.option,formInline)"
                   @visible-change="visibleChange">
          <el-option v-for="item in list.option" :key="item.value" 
          :label="list.labelName ? item[list.labelName] : item.labelname" 
          :value="list.labelCode ? item[list.labelCode] : item.value"
                     :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-form-item>
      <!--单选-->
      <el-form-item v-if="list.type==='radio' && list.showhide" class="form_list" :label="list.label" :prop="list.name"
                    :style="{width: list.width,maxWidth:'100%'}">
        <el-radio-group v-model="formInline[list.name]" :size="list.size"
                        @change="radioChange(arguments[0],list.name,list.radiochildren)"
                        style="width:100%">
          <el-radio v-for="item in list.radiochildren" :key="item.value" :label="item.value" :disabled="list.disabled"
                    :border="item.border" :size="list.size">{{item.labelname}}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="list.type==='radios' && list.showhide" :class="{bitian:list.requireds,'form_list':true,[list.labelClass]:true}" :label="list.label" :prop="list.name"
                    :style="{width: list.width,maxWidth:'100%'}">
        <el-radio-group v-model="formInline[list.name]" :size="list.size"
                        @change="radioChange(arguments[0],list.name,list.radiochildren)"
                        style="width:100%">
          <el-radio v-for="item in list.radiochildren" :key="item.value" :label="item.value" :disabled="list.disabled"
                    :border="item.border" :size="list.size">{{item.labelname}}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <!--多选-->
      <el-form-item v-if="list.type==='checkbox' && list.showhide" :class="{bitian:list.requireds,'form_list':true,[list.labelClass]:true}" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-checkbox-group v-model="formInline[list.name]" :size='list.size' :disabled='list.disabled' :min='list.min'
                           :max='list.max' @change="checkboxChange(arguments[0],list.name,list.checkboxchildren)"
                           style="width:100%">
          <el-checkbox v-for="item in list.checkboxchildren" :key="item.value" :label="item.value" :name="item.name"
                       :disabled="item.disabled" :border="item.border" :size="list.size" :checked="item.checked">
            {{item.labelname}}
            <!-- <span class="iconClicks" @click.prevent="ceshi(item)" v-if="list.iconShow">
              <i class="iconfont f-s-18" :class="list.iconName"></i>
            </span> -->
            <el-popover
              v-if="list.iconShow"
              placement="top"
              title="说明"
              width="400"
              trigger="hover"
              :content="item.title">
              <i class="iconfont f-s-18" @click.prevent="ceshi(item)" :class="list.iconName" slot="reference"></i>
            </el-popover>
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
       <!--多选放到填写条件里-->
      <el-form-item v-if="list.type==='checkboxAline' && list.showhide" class="form_list" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-checkbox-group v-model="formInline[list.name]" :size='list.size' :class="list.class">
            <el-checkbox :key="item.value" :label="item.value" :name="item.name" :border="list.border" v-for="item in list.checkboxchildren">
              {{ item.labelname }}
               <el-popover
                  v-if="list.iconShow"
                  placement="bottom"
                  title="说明"
                  width="400"
                  trigger="hover"
                  :content="item.title">
                  <i style="vertical-align:bottom" class="iconfont f-s-18" @click.prevent="ceshi(item)" :class="list.iconName" slot="reference"></i>
                </el-popover>
            </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!--级联选择器-->
      <el-form-item v-if="list.type==='cascader' && list.showhide" class="form_list treeSelect" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-cascader :options="list.treeData" v-model="formInline[list.name]" :props="props"
                     :placeholder="list.placeholder"
                     :separator="list.separator" :disabled="list.disabled" :clearable="list.clearable"
                     :expand-trigger="list.expandtrigger" :show-all-levels="list.showalllevels"
                     :filterable="list.filterable"
                     :debounce="list.debounce" :change-on-select="list.changeonselect" :size="list.size"
                     :before-filter="list.beforefilter"
                     @change="handleChange" @focus="cascaderFocus" @blur="cascaderBlur" style="width:100%">
        </el-cascader>
      </el-form-item>
      <!--Treeselect-->
      <el-form-item v-if="list.type==='treeselect' && list.showhide" :class="{bitian:list.requireds,'form_list':true, 'tree_select':true}" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <treeselect :options='list.treedata' v-model="formInline[list.name]" :multiple="list.multiple"
                    :placeholder="list.placeholder"
                    :closeOnSelect="list.closeonselect" :defaultExpandLevel="list.defaultExpandLevel || 0" :flat="list.flat"
                    :limit="1" :limitText="limitText" :searchable="list.searchable"
                    :disabled="list.disabled || disabled" @select="selectDepart(arguments[0],list.name,)"
                    @input="inputFun(arguments[0],list.name,list.treedata,'treeselect')" :alwaysOpen="false"
                    :appendToBody="appendToBody" :load-options="list.name == 'parentcode'?loadOptions1:loadOptions"
                    :clearAllText="clearAllText" :clearValueText="clearAllText" @open="openFun" @close="closeFun"
                    style="width:100%" :class="{'disabledtree':disabled}"/>
      </el-form-item>
      <!--TimePicker 时间选择器-->
      <el-form-item v-if="list.type==='TimePicker' && list.showhide" class="form_list" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-time-picker :placeholder="list.placeholder" v-model="formInline[list.name]" :readonly="list.readonly"
                        :disabled="list.disabled" :editable="list.editable" :clearable="list.clearable"
                        :size="list.size" :is-range="list.isRange" :start-placeholder="list.startplaceholder"
                        :end-placeholder="list.endplaceholder" :format="list.format"
                        :arrow-control="list.arrowcontrol" :align="list.align"
                        :range-separator="list.rangeseparator" :default-value="list.defaultvalue"
                        :prefix-icon="list.prefixicon" :value-format="list.valueformat"
                        :selectableRange="list.selectablerange"
                        :clear-icon="list.clearicon" style="width:100%">
        </el-time-picker>
      </el-form-item>
      <!--DatePicker 日期选择器-->
      <el-form-item v-if="list.type==='DatePicker' && list.showhide && list.isdiadic!=true" :class="{bitian:list.requireds,'form_list':true,[list.labelClass]:true}" 
                    :label="list.label" :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-date-picker v-model="formInline[list.name]" :readonly="list.readonly"
                        :disabled="list.disabled" :editable="list.editable" :clearable="list.clearable"
                        :size="list.size" :start-placeholder="list.startplaceholder"
                        :end-placeholder="list.endplaceholder" :type="list.datetype" :format="list.format"
                        :align="list.align" :value-format="list.valueformat"
                        :picker-options="list.pickeroptions" :range-separator="list.rangeseparator"
                        :default-value="list.defaultvalue" :placeholder="list.placeholder"
                        :default-time="list.defaulttime" :unlink-panels="list.unlinkpanels"
                        :prefix-icon="list.prefixicon"
                        :clear-icon="list.clearicon" style="width: 100%"
                        @change="DatePickerTimeData">
        </el-date-picker>
      </el-form-item>
      <!--自定义双控件-->
      <!--text-范围-->
      <el-form-item v-if="list.type==='textScope' && list.showhide" class="form_list input_t" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-input type="text" v-model="customForm[list.name+'1']" :placeholder="list.startplaceholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize"
                  @change='textScopeChange(list.label,customForm[list.name+1],customForm[list.name+2],list.name,"start")'
                  style="width:43%"></el-input>
        <span>&nbsp;-&nbsp;</span>
        <el-input type="text" v-model="customForm[list.name+'2']" :placeholder="list.endplaceholder"
                  :disabled="list.disabled" :clearable="list.clearable" :readonly="list.readonly"
                  :maxlength="list.maxlength" :minlength="list.minlength" :size="list.size"
                  :prefix-icon="list.prefixicon" :suffix-icon="list.suffixicon" :rows="list.rows"
                  :max="list.max" :min="list.min" :auto-complete="list.autocomplete" :step="list.step"
                  :autofocus="list.autofocus" :resize="list.resize"
                  @change="textScopeChange(list.label,customForm[list.name+'1'],customForm[list.name+2],list.name)"
                  style="width:43%"></el-input>
      </el-form-item>
      <!--日期选择器-范围-->
      <el-form-item v-if="list.type==='DatePickerScope' && list.showhide" class="form_list" :label="list.label"
                    :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-date-picker :type="list.datetype" v-model="customForm[list.name+'1']"
                        :readonly="list.readonly" :disabled="list.disabled" :editable="list.editable"
                        :clearable="list.clearable" :size="list.size" :start-placeholder="list.startplaceholder"
                        :end-placeholder="list.endplaceholder" :format="list.format"
                        :align="list.align" :value-format="list.valueformat"
                        :picker-options="list.pickeroptionsstart" :range-separator="list.rangeseparator"
                        :default-value="list.defaultvalue" :default-time="list.defaulttime"
                        :unlink-panels="list.unlinkpanels" :prefix-icon="list.prefixicon"
                        :clear-icon="list.clearicon" :placeholder="list.startplaceholder"
                        @change="DatePickerScopeChange(customForm[list.name+'1'],list.name,list.characteristic,list.valueformat1,list.monitertype,list.control)"
                        style="width:45%;float: left;">
        </el-date-picker>
        <span style="width:10%;text-align:center;float: left;">&nbsp;-&nbsp;</span>
        <el-date-picker :type="list.datetype" v-model="customForm[list.name+'2']"
                        :readonly="list.readonly" :disabled="list.disabled" :editable="list.editable"
                        :clearable="list.clearable" :size="list.size" :start-placeholder="list.startplaceholder"
                        :end-placeholder="list.endplaceholder" :format="list.format1"
                        :align="list.align" :value-format="list.valueformat1"
                        :picker-options="!list.noDisabledDate && pickeroptions[list.pickeroptionsend]"
                        :range-separator="list.rangeseparator" :placeholder="list.endplaceholder"
                        :default-value="list.defaultvalue" :default-time="list.defaulttime"
                        :unlink-panels="list.unlinkpanels" :prefix-icon="list.prefixicon"
                        :clear-icon="list.clearicon" style="width:45%;float: left;">
        </el-date-picker>
      </el-form-item>
      <!--自定义控件（年-季度）-->
      <el-form-item v-if="list.type==='yearQuarter'" class="form_list" :label="list.label" :prop="list.name"
                    :style="{width: list.width,maxWidth:'100%'}">
        <el-date-picker v-model="customForm[list.name+'1']" :readonly="list.readonly"
                        :disabled="list.disabled" :editable="list.editable" :clearable="list.clearable"
                        :size="list.size" :placeholder="list.placeholder"
                        type="year" format="yyyy" :align="list.align" value-format="yyyy"
                        :range-separator="list.rangeseparator" :default-value="list.defaultvalue"
                        :default-time="list.defaulttime" :unlink-panels="list.unlinkpanels"
                        :prefix-icon="list.prefixicon" :clear-icon="list.clearicon"
                        @change="yearQuarterChange(list.name+'1',list.name+'2')" style="width: 42%;float: left;">
        </el-date-picker>
        <span style="float: left;">-</span>
        <el-select v-model="customForm[list.name+'2']" placeholder="请选择季度"
                   @change="yearQuarterSChange(list.name+'1',list.name+'2')" style="width: 42%;float: left;">
          <el-option v-for="item in quarteroPtions" :key="item.value" :label="item.label"
                     :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <!--自定义控件（年）-->
      <el-form-item v-if="list.type==='yearControl'" class="form_list" :label="list.label" :prop="list.name"
                    :style="{width: list.width,maxWidth:'100%'}">
        <el-date-picker v-model="customForm[list.name+'1']" :readonly="list.readonly"
                        :disabled="list.disabled" :editable="list.editable" :clearable="list.clearable"
                        :size="list.size" :placeholder="list.placeholder"
                        type="year" format="yyyy" :align="list.align" value-format="yyyy"
                        :range-separator="list.rangeseparator" :default-value="list.defaultvalue"
                        :default-time="list.defaulttime" :unlink-panels="list.unlinkpanels"
                        :prefix-icon="list.prefixicon" :clear-icon="list.clearicon"
                        @change="yearQuarterChange(list.name+'1',list.name+'2')" style="width: 100%;float: left;">
        </el-date-picker>
      </el-form-item>
      <!--自定义单控件-双值-->
      <!--DatePicker 日期选择器-->
      <el-form-item v-if="list.type==='DatePicker' && list.showhide && list.isdiadic" class="form_list"
                    :label="list.label" :prop="list.name" :style="{width: list.width,maxWidth:'100%'}">
        <el-date-picker :type="list.datetype" :placeholder="list.placeholder" v-model="customFormOne[list.name]"
                        :readonly="list.readonly"
                        :disabled="list.disabled" :editable="list.editable" :clearable="list.clearable"
                        :size="list.size" :start-placeholder="list.startplaceholder"
                        :end-placeholder="list.endplaceholder" :format="list.format"
                        :align="list.align" :value-format="list.valueformat"
                        :picker-options="list.pickeroptions" :range-separator="list.rangeseparator"
                        :default-value="list.defaultvalue" :default-time="list.defaulttime"
                        :unlink-panels="list.unlinkpanels"
                        :prefix-icon="list.prefixicon" :clear-icon="list.clearicon"
                        @change="changeCustomFormOne(list.name,list.datetype,list.valueformatend)" style="width:100%">
        </el-date-picker>
      </el-form-item>
      <!-- 插槽 -->
      <el-form-item v-if="list.type==='slot' && list.label" class="form_list" :label="list.label" :prop="list.name"
                    :style="{width: list.width,maxWidth:'100%'}">
        <slot :name="list.name"></slot>
      </el-form-item>
       <slot v-else :name="list.name"></slot>
    </template>
  </div>
</template>
<!--
formList：表单数据
formInline：表单的填写值
customArrKey：双控件所对应的key

/*表单字段说明*/
"data":{
  /*添加/编辑/查询的表单数据*/
  "addformdata/editformdata/queryformdata":[
    /*公共属性*/
    {
      /*是否有清空按钮,true表示有,false表示没有,默认为true*/
      "clearable":true,
      /*表单验证的方式,如blur、change,默认为""*/
      "validtriggers":"blur",
      /*必传,表单名称,用来获取表单中的值*/
      "name":"sectionname",
      /*表单元素的宽度,默认为""*/
      "width":"360px",
      /*表单元素是否显示,true表示显示,false表示隐藏,默认为true*/
      "showhide":true,
      /*表单元素是否可编辑,true表示不可编辑,false表示可编辑,默认为false*/
      "disabled":false,
      /*表单元素内容为空时显示的内容,默认为""*/
      "placeholder":"请输入断面名称",
      /*必传,表单元素的label值,用来说明该表单中的值表示的含义*/
      "label":"断面名称",
      /*表单验证错误时显示的错误信息,默认为"",多个用$+$进行连接,数量和validrules数量一样*/
      "validmessage":"经度不能为空！$+$经度输入不正确！",
      /*必传,表单元素的类型,"text"为文字输入框,"number"为数字输入框,"password"为密码输入框,
      "textarea"为文本框,"select"为选择器,"radio"为单选框,"checkbox"为多选框,"cascader"为级联选择器,
      "treeselect"为下拉选择器,"TimePicker"为时间选择器,"DatePicker"为日期选择器,"textScope"为文本范围双控件,
      "DatePickerScope"为日期范围选择器,yearQuarter"为年-季度选择器*/
      "type":"text",
      /*表单验证方法,默认为"",多个用$+$进行连接,数量和validmessage数量一样*/
      "validrules":"isNonEmpty$+$isLongitude",
      /*表单元素是否只读,true表示只读,false表示不是只读,默认为false*/
      "readonly":false,
    },
    /*type为text、number、password、textarea、textScope的属性*/
    {
      "type":"text",
      /*最大输入长度,值为number类型,默认为""*/
      "maxlength":300,
      /*最小输入长度,值为number类型,默认为""*/
      "minlength":0,
      /*输入框尺寸,可选值medium / small / mini,默认为""*/
      "size":"small",
      /*输入框头部图标,默认为""*/
      "prefixicon":"",
      /*输入框尾部图标,默认为""*/
      "suffixicon":"",
      /*type为"textarea"传值,输入框行数,默认为2*/
      "rows":5,
      /*输入框设置最大值,默认为""*/
      "max":"",
      /*输入框设置最小值,默认为""*/
      "min":"",
      /*输入框自动补全,可选值"on"/"off",默认为"off"*/
      "autocomplete":"",
      /*输入框设置数字字段的合法数字间隔,默认为""*/
      "step":"",
      /*输入框自动获取焦点,true为自动获取焦点,false为不自动获取焦点,默认为false*/
      "autofocus":false,
      /*控制是否能被用户缩放,可选值有none, both, horizontal,vertical,默认为""*/
      "resize":"",
    },
    /*type为autocomplete属性*/

    /*type为select的属性*/
    {
      "type":"select",
      /*是否多选,true为可多选,false为不可多选,默认为false*/
      "multiple":false,
      /*是否可搜索,true为可搜索,false为不可搜索,默认为false*/
      "filterable":false,
      /*多选时是否将选中值按文字的形式展示,true为展示,false为不展示,默认为false*/
      "collapsetags":false,
      /*多选时用户最多可以选择的项目数,为 0 则不限制,默认为0*/
      "multiplelimit":0,
      /*是否允许用户创建新条目,需配合 filterabl e 使用,true为允许,false为允许,默认为false*/
      "allowcreate":false,
      /*是否将弹出框插入至 body 元素,true为插入至body元素,false为不插入至body元素,默认为true*/
      "popperappendtobody":false,
      /*type为select时必传,选择器可选择的内容*/
      "option":
        [
          {
            /*选择器选择的内容*/
            "labelname":"三其桥",
            /*选择器选择的值*/
            "value":"1"
          },
          {
            "labelname":"哈拉直沟入湟口",
            "value":"10"
          }
        ]
    },
    /*type为radio的属性*/
    {
      "type":"radio",
      /*type为radio时必传,单选框可选择的内容*/
      "radiochildren":
        [
          {
            /*单选框中选择的内容*/
            "labelname":"考核断面",
            /*单选框中选择的值*/
            "value":"1"
          },
          {
            "labelname":"普通断面",
            "value":"0"
          }
        ]
    },
    /*type为checkbox的属性*/
    {
      "type":"radio",
      /*type为checkbox时必传,多选框可选择的内容*/
      "checkboxchildren":
        [
          {
            /*多选框中选择的内容*/
            "labelname":"考核断面",
            /*多选框中选择的值*/
            "value":"1"
          },
          {
            "labelname":"普通断面",
            "value":"0"
          }
        ]
    },
    /*type为cascader的属性*/
    {
      "type":"cascader",
      /*type为cascader必传,级联选择器可选择的内容*/
      "treeData":
        [
          {
            /*级联选择器中的选择值*/
            "value":"630203000000",
            /*级联选择器中选择的内容*/
            "label":"平安区"
          },
          {
            "value":"630222000000",
            "label":"民和回族土族自治县"
          },
          {
            "value":"630202000000",
            "label":"乐都区"
          },
          {
            "value":"630223000000",
            "label":"互助土族自治县"
          },
          {
            "value":"630224000000",
            "label":"化隆回族自治县"
          },
          {
            "value":"630225000000",
            "label":"循化撒拉族自治县"
          }
        ],
      /*选项分隔符,默认为"/"*/
      "separator":"/",
      /*级联选择器中次级菜单的展开方式,可传值有click、hover默认值为"click"*/
      "expandtrigger":"click",
      /*级联选择器中输入框中是否显示选中值的完整路径,true表示显示,false表示不显示,默认为true*/
      "showalllevels":true,
      /*级联选择器中输入框中是否可搜索,true表示可搜索,false表示不可搜索,默认为false*/
      "filterable":false,
      /*搜索关键词输入的去抖延迟,毫秒,默认为300*/
      "debounce":300,
      /*级联选择器中输入框中是否允许选择任意一级的选项,true表示可选择,false表示不可选择,默认为false*/
      "changeonselect":false,
    },
    /*type为treeselect的属性*/
    {
      "type":"treeselect",
      /*下拉树选择内容后是否收起下拉菜单,true表示收起,false表示不收起,默认为true*/
      "closeonselect":true,
      /*下拉树选择父级节点后,子节点是否全部选中,false为全部选中,true为不选中子节点,默认为false*/
      "flat":false,
      /*type为treeselect必传,下拉树可选择的内容*/
      "treedata":
        [
          {
            /*下拉树中的选择值*/
            "id":"630203000000",
            /*下拉树中选择的内容*/
            "label":"平安区"
          },
          {
            "id":"630222000000",
            "label":"民和回族土族自治县"
          },
          {
            "id":"630202000000",
            "label":"乐都区"
          },
          {
            "id":"630223000000",
            "label":"互助土族自治县"
          },
          {
            "id":"630224000000",
            "label":"化隆回族自治县"
          },
          {
            "id":"630225000000",
            "label":"循化撒拉族自治县"
          }
        ]
    },
    /*type为DatePicker、TimePicker、DatePickerScope、yearQuarter的属性*/
    {
      "type":"DatePicker",
      /*文本框可输入,true为可输入,false为不可输入,默认为true*/
      "editable":true,
      /*输入框尺寸,可选值large, small, mini,默认为""*/
      "size":"",
      /*对齐方式,可选值left, center, right,默认为"left"*/
      "align":"left",
      /*当前时间日期选择器特有的选项,用来控制日期的选怎范围,默认为{}*/
      "pickeroptions":{},
      /*选择范围时的分隔符,默认为"-"*/
      "rangeseparator":"-",
      /*选择日期后的默认具体时刻,默认为""*/
      "defaulttime":"",
      /*在范围选择器里取消两个日期面板之间的联动,true为取消,false为不取消,默认为false*/
      "unlinkpanels":false,
      /*自定义头部图标的类名,默认为"el-icon-date"*/
      "prefixicon":"el-icon-date",
      /*自定义清空图标的类名,默认为"el-icon-circle-close"*/
      "clear-icon":"el-icon-circle-close",
      /*范围选择器中第一个输入框中内容为空时的提示信息,默认为""*/
      "startplaceholder":"请选择开始时间",
      /*范围选择器中第一个输入框中容为空时的提示信息,默认为""*/
      "endplaceholder":"请选择结束时间",
      /*type为"TimePicker"传值,是否为时间范围选择,默认为false*/
      "isRange":false,
      /*时间默认值,默认为""*/
      "defalutvalue":"",
      /*type为"TimePicker"、"DatePicker"、"DatePickerScope"、 "yearQuarter"时必传,
      表示日期的类型,可传参数有year、month、date、datetime*/
      "datetype":"year",
      /*type为"TimePicker"、"DatePicker"、"DatePickerScope"、"yearQuarter"时必传,表示日期的格式,
      可传参数有yyyy、yyyy-MM、yyyy-MM-dd、yyyy-MM-dd HH:mm:ss,type为year时是yyyy,
      type为month时是yyyy-MM,type为date时是yyyy-MM-dd,type为datetime时是yyyy-MM-dd HH:mm:ss,该值和format一样*/
      "valueformat":"yyyy",
      /*type为"TimePicker"、"DatePicker"、"DatePickerScope"、"yearQuarter"时必传,表示日期的格式,
      可传参数有yyyy、yyyy-MM、yyyy-MM-dd、yyyy-MM-dd HH:mm:ss,type为year时是yyyy,type为month时是yyyy-MM,
      type为date时是yyyy-MM-dd,type为datetime时是yyyy-MM-dd HH:mm:ss,该值和valueformat一样*/
      "format":"yyyy",
    }
  ],
  /*双控件中的值,包含所有的双控件中的name值*/
  "twocontrolrange":[],
  /*必传,表单中传给后台的值,该对象的每个键值对应表单数据中每个对象的name值*/
  "ruleform":
    {
      "isjunctionsection":"",
      "fk_upstreamid":"",
      "fk_controllevelcode":"",
      "monitorpointyear":"",
      "fk_waterfunclasscode":"",
    }
}
-->
<script>
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "../../assets/extendcss/treeSelect.less";
import configBasics from "../../utils/configBasics";

export default {
  name: "SuperForm",
  components: { Treeselect },
  props: [
    "formList",
    "formInline",
    "customArrKey",
    "disabled",
    "listJson",
    "hasPollutant",
    "editIndex",
    "indexNum"
  ],
  data() {
    return {
      /*tree配置*/
      props: {
        value: "id",
        label: "label",
        children: "children"
      },
      restaurants: [],
      clearAllText: "清空",
      appendToBody: false,
      /*自定义控件-双控件*/
      customForm: {},
      pickeroptions: {},
      /*自定义控件-单控件*/
      customFormOne: {},
      /*30天*/
      monthOne: ["4", "6", "9", "11", "04", "06", "09"],
      /*31天*/
      monthTwo: [
        "1",
        "3",
        "5",
        "7",
        "8",
        "10",
        "12",
        "01",
        "03",
        "05",
        "07",
        "08"
      ],
      /*季度*/
      quarteroPtions: [
        {
          label: "一季度",
          value: "1"
        },
        {
          label: "二季度",
          value: "2"
        },
        {
          label: "三季度",
          value: "3"
        },
        {
          label: "四季度",
          value: "4"
        }
      ],
      show: true,
      fileList: {}
    };
  },
  /*总表单数据-预设类型（数据）-自定义控件的key-自定义控件（单控-双值）的数据*/
  methods: {
    ceshi(item) {
      console.log(item);
    },
    uploadClick(item) {
      this.$emit("uploadClick", item);
    },
    reload() {
      this.show = false;
      setTimeout(() => (this.show = true), 0);
    },
    /*级联选择器改变时事件*/
    handleChange(value) {
      // console.log(value);
    },
    cascaderFocus() {},
    cascaderBlur() {},
    // 时间监听事件
    DatePickerTimeData(value, name) {
      let that = this;
      that.$emit("DatePickerTimeData", value);
    },
    /*多余时显示的*/
    limitText(count) {
      return "+" + count;
    },
    /*treeSelect改变时事件*/
    inputFun(value, name, option, type) {
      // console.log(value, name, option, "treeSelect改变时事件");
      /*用户角色*/
      if (name === "roles_id") {
        let htmlP = this.$route.path.split("/").reverse()[0];
        if (htmlP === "userAdd" || htmlP === "userRedact") {
          this.selectTreeFun(value);
        }
      }
      this.$emit("inputFun", value, name, option);
    },
    // treeSelect改变时事件
    selectDepart(val, name) {
      this.$emit("selectDepart", val, name);
      this.BUS.$emit("selectDepart", val, name);
    },
    /*角色改变时的事件*/
    selectTreeFun(data) {
      let that = this;
      let loading = this.$loading(configBasics.loading);
      let addList = {};
      if (data) {
        addList.roleids = data;
        if (typeof data == "object") {
          addList.roles_id = data;
        } else {
          addList.roles_id = [];
          addList.roles_id.push(data);
        }
      } else {
        addList.roles_id = [];
      }
      that.$http
        .post(
          that.VUE_APP_BASE_API + "roleController/getRoleAuthByRoleIDs",
          addList
        )
        .then(function (success) {
          let dataList = success.data;
          if (dataList.flag === "success") {
            that.$emit("selectData", dataList.data);
          } else {
            that.$message({
              type: "error",
              message: "获取角色权限失败!",
              duration: 1000
            });
          }
          loading.close();
        })
        .catch(function (error) {
          loading.close();
          that.$message({
            type: "error",
            message: "获取角色权限失败!",
            duration: 1000
          });
        });
    },
    //自动匹配
    querySearch(queryString, cb) {
      var restaurants = [];
      this.formList.map((v, i) => {
        if (v.name == "shortername") {
          restaurants = v.option;
        }
      });
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    //
    handleSelect(item) {},
    /*打开时事件*/
    openFun() {
      this.appendToBody = true;
    },
    /*关闭时事件*/
    closeFun() {
      this.appendToBody = true;
    },
    inputBlur(name, modelVal) {
      // console.log(name, modelVal, "输入框改变事件");
      let that = this;
      /*返回事件*/
      that.$emit("inputBlur", name, modelVal);
    },
    /*下拉框改变事件*/
    selectChange(value, name, option, formInline) {
      // console.log(this.indexNum);
      let nameVal = "";
      let obj = {};
      option.map((e, i) => {
        if (e.code == value) {
          nameVal = e.labelname;
          obj = e;
        }
      });
      // console.log(value, name, option, formInline, '下拉框改变事件');
      let that = this;
      /*返回事件*/
      that.$emit("selectChange", value, name, option, nameVal, obj);
      that.$emit(
        "selectChangeSon",
        value,
        name,
        option,
        nameVal,
        this.indexNum
      );
      /*返回事件-特殊*/
      if (name === "fkpollutantcode") {
        /*特征污染物名称*/
        if (that.editIndex != undefined) {
          that.$emit("fkpollutantcode", value, option, that.editIndex);
        } else {
          that.$emit("fkpollutantcode", value, option);
        }
      } else if (name === "version") {
        /*特征污染物版本号*/
        that.$emit("version", value, option);
      }
    },
    visibleChange(val) {
      // console.log(val)
    },
    /*单选按钮改变事件*/
    radioChange(value, name, option) {
      let that = this;
      let nameVal = "";
      let obj = {};
      option.map((e, i) => {
        if (e.code == value) {
          nameVal = e.labelname;
          obj = e;
        }
      });
      that.$emit("radioChange", value, name, option, nameVal, obj);
      // console.log(value, name, option, '单选按钮改变事件');
    },
    /*多选按钮改变事件*/
    checkboxChange(value, name, option) {
      let that = this;
      that.$emit("checkboxChange", value, name, option);
      // console.log(value, name, option, '多选按钮改变事件');
    },
    /*自定义数据处理*/
    customModelFun(data, list) {
      let that = this;
      // console.log("自定义数据处理");
      if (list && list.length > 0) {
        for (let item in data) {
          if (list.indexOf(item) !== -1) {
            let key1 = item + "1";
            let val1;
            if (that.customForm[key1]) {
              val1 = that.customForm[key1];
            } else {
              val1 = null;
            }
            let key2 = item + "2";
            let val2;
            if (that.customForm[key2]) {
              val2 = that.customForm[key2];
            } else {
              val2 = null;
            }
            data[item] = "";
            if (val1 == null && val2 == null) {
              data[item] = [];
            } else {
              data[item] = (val1 + "," + val2).split(",");
            }
          }
        }
      }
    },
    /*日期选择器范围-开始日期改变事件*/
    DatePickerScopeChange(
      val,
      key,
      characteristic,
      valueformat1,
      monitertype,
      control
    ) {
      if (control) {
        let that = this;
        let endVal, startVal, valueFormat;
        // console.log(val, key, characteristic, valueformat1, '日期选择器范围-开始日期改变事件');
        if (val && valueformat1) {
          startVal = val.split(" ")[0];
          valueFormat = valueformat1.split(" ")[1];
          // console.log(valueFormat, '是否有时分秒');
          endVal = that.customForm[key + "2"];
          if (
            valueFormat === undefined ||
            valueFormat === null ||
            valueFormat === ""
          ) {
            that.customForm[key + "2"] = "";
          } else {
            that.customForm[key + "2"] = "";
          }
          that.customForm[key + "2"] = endVal;
          // console.log(that.customForm[key + '2'], '结束日期');
          /*限制结束日期的选择*/
          if (
            valueFormat === undefined ||
            valueFormat === null ||
            valueFormat === ""
          ) {
            if (val.split("-").length === 1) {
              val = val + "-01-01";
            } else if (val.split("-").length === 2) {
              val = val + "-01";
            }
          }
          // console.log(val, "配置-结束日期");
          let date = new Date(val);
          let ops = {};
          if (val > that.customForm[key + "2"]) {
            that.customForm[key + "2"] = "";
          }
          for (let i = 0; i < that.formList.length; i++) {
            if (
              that.formList[i].characteristic === characteristic &&
              characteristic
            ) {
              // console.log(monitertype, 'monitertype');
              /*实时、分钟数据*/
              if (monitertype) {
                if (
                  monitertype == "realtimedata" ||
                  monitertype == "minutedata"
                ) {
                  let oneday = date.getTime() + 24 * 3600 * 1000;
                  ops = {
                    disabledDate(time) {
                      return (
                        time.getTime() < date.getTime() - 8.64e7 ||
                        time.getTime() > oneday ||
                        time.getTime() > new Date().getTime() - 8.64e6
                      );
                    }
                  };
                } else if (monitertype == "hourdata") {
                  let onemonth =
                    new Date(val + ":00").getTime() + 30 * 24 * 3600 * 1000;
                  ops = {
                    disabledDate(time) {
                      return (
                        time.getTime() < date.getTime() - 8.64e7 ||
                        time.getTime() > onemonth ||
                        time.getTime() > new Date().getTime() - 8.64e6
                      );
                    }
                  };
                }
              } else {
                ops = {
                  disabledDate(time) {
                    return (
                      time.getTime() < date.getTime() - 8.64e7 ||
                      time.getTime() > new Date().getTime() - 8.64e6
                    );
                  }
                };
              }
              that.pickeroptions["pickerOptionsEnd" + characteristic] = ops;
              // console.log(that.pickeroptions, '配置', date.getTime());
              break;
            }
          }
        } else {
          let date = new Date("1970-01-01");
          let ops = {};
          for (let i = 0; i < that.formList.length; i++) {
            if (
              that.formList[i].characteristic == characteristic &&
              characteristic
            ) {
              ops = {
                disabledDate(time) {
                  return (
                    time.getTime() < date.getTime() - 8.64e7 ||
                    time.getTime() > new Date().getTime() - 8.64e6
                  );
                }
              };
              that.pickeroptions["pickerOptionsEnd" + characteristic] = ops;
              // console.log(that.pickeroptions, '配置', date.getTime());
              break;
            }
          }
        }
      } else {
        let that = this;
        let endVal, startVal, valueFormat;
        // console.log(val, key, characteristic, valueformat1, '日期选择器范围-开始日期改变事件');
        if (val && valueformat1) {
          startVal = val.split(" ")[0];
          valueFormat = valueformat1.split(" ")[1];
          // console.log(valueFormat, '是否有时分秒');
          if (
            valueFormat === undefined ||
            valueFormat === null ||
            valueFormat === ""
          ) {
            that.customForm[key + "2"] = val;
          } else {
            that.customForm[key + "2"] = val + " " + valueFormat;
          }
          // console.log(that.customForm[key + '2'], '结束日期');
          /*限制结束日期的选择*/
          if (
            valueFormat === undefined ||
            valueFormat === null ||
            valueFormat === ""
          ) {
            if (val.split("-").length === 1) {
              val = val + "-01-01";
            } else if (val.split("-").length === 2) {
              val = val + "-01";
            }
          }
          // console.log(val, "配置-结束日期");
          let date = new Date(val);
          let ops = {};
          for (let i = 0; i < that.formList.length; i++) {
            if (
              that.formList[i].characteristic === characteristic &&
              characteristic
            ) {
              // console.log(monitertype, 'monitertype');
              /*实时、分钟数据*/
              if (monitertype) {
                if (
                  monitertype == "realtimedata" ||
                  monitertype == "minutedata"
                ) {
                  let oneday = date.getTime() + 24 * 3600 * 1000;
                  ops = {
                    disabledDate(time) {
                      return (
                        time.getTime() < date.getTime() - 8.64e7 ||
                        time.getTime() > oneday ||
                        time.getTime() > new Date().getTime() - 8.64e6
                      );
                    }
                  };
                } else if (monitertype == "hourdata") {
                  let onemonth =
                    new Date(val + ":00").getTime() + 30 * 24 * 3600 * 1000;
                  ops = {
                    disabledDate(time) {
                      return (
                        time.getTime() < date.getTime() - 8.64e7 ||
                        time.getTime() > onemonth ||
                        time.getTime() > new Date().getTime() - 8.64e6
                      );
                    }
                  };
                }
              } else {
                ops = {
                  disabledDate(time) {
                    return (
                      time.getTime() < date.getTime() - 8.64e7 ||
                      time.getTime() > new Date().getTime() - 8.64e6
                    );
                  }
                };
              }
              that.pickeroptions["pickerOptionsEnd" + characteristic] = ops;
              // console.log(that.pickeroptions, '配置', date.getTime());
              break;
            }
          }
        } else {
          let date = new Date("1970-01-01");
          let ops = {};
          for (let i = 0; i < that.formList.length; i++) {
            if (
              that.formList[i].characteristic == characteristic &&
              characteristic
            ) {
              ops = {
                disabledDate(time) {
                  return (
                    time.getTime() < date.getTime() - 8.64e7 ||
                    time.getTime() > new Date().getTime() - 8.64e6
                  );
                }
              };
              that.pickeroptions["pickerOptionsEnd" + characteristic] = ops;
              // console.log(that.pickeroptions, '配置', date.getTime());
              break;
            }
          }
        }
      }
    },
    /*文本框范围-改变事件*/
    textScopeChange(label, startVal, endVal, name, start) {
      let that = this;
      if (start) {
        if (startVal > endVal && endVal) {
          that.customForm[name + "2"] = "";
          that.$message({
            message: label + "输入不正确,请输入大于等于开始范围的值！",
            type: "warning",
            duration: 1000
          });
        }
      } else {
        if (startVal > endVal) {
          that.customForm[name + "2"] = "";
          that.$message({
            message: label + "输入不正确,请输入大于等于开始范围的值！",
            type: "warning",
            duration: 1000
          });
        }
      }
    },
    /*树控件-懒加载*/
    loadOptions({ action, parentNode, callback }) {},
    /*树控件-懒加载(流域)*/
    loadOptions1({ action, parentNode, callback }) {
      var that = this;
      var data = {
        code: parentNode.id
      };
      that.$http
        .post(
          that.SUPERVISION_PLATFORM_MANAGE +
            "supervisionPlatform/pubCode/getAllBasinInfoByCode",
          data
        )
        .then(function (success) {
          var dataList = success.data;
          if (dataList.flag == "success") {
            parentNode.children = dataList.data;
          }
          callback();
        });
    },
    /*判断是否为闰年*/
    isLeapYear(year) {
      return year % 4 == 0 && (year % 100 != 0 || year % 400 == 0);
    },
    /*自定义控件-单控件*/
    changeCustomFormOne(key, datetype, valueformatend) {
      let that = this;
      // console.log(key, datetype, valueformatend, '自定义控件-单控件');
      if (that.formList) {
        for (let item in that.formInline) {
          if (item == key) {
            if (datetype == "date") {
              let val1 = that.customFormOne[item];
              if (val1 == "" || val1 == undefined || val1 == null) {
                that.formInline[item] = [];
              } else {
                let val2 = valueformatend.split(" ")[1];
                let val3 = val1.split(" ")[0];
                that.formInline[item] = "";
                if (val2) {
                  that.formInline[item] = val1 + "," + val3 + " " + val2;
                } else {
                  that.formInline[item] = val1 + "," + val3;
                }
              }
              // console.log(that.formInline);
            } else if (datetype === "month") {
              if (
                that.customFormOne[item] == "" ||
                that.customFormOne[item] == null ||
                that.customFormOne[item] == undefined
              ) {
                that.formInline[item] = [];
              } else {
                let monthVal1 = that.customFormOne[item].split(" ");
                let year = monthVal1[0].split("-")[0];
                let month = monthVal1[0].split("-")[1];
                let monthVal2 = valueformatend.split(" ")[1];
                let monthVal4;
                if (year != "" && that.isLeapYear(year)) {
                  /*闰年-29*/
                  if (month == "2" || month == "02") {
                    monthVal4 = "29";
                  }
                } else if (!that.isLeapYear(year)) {
                  /*平年-28*/
                  if (month == "2" || month == "02") {
                    monthVal4 = "28";
                  }
                }
                if (that.monthOne.indexOf(month) != -1) {
                  monthVal4 = "30";
                } else if (that.monthTwo.indexOf(month) != -1) {
                  monthVal4 = "31";
                }
                that.formInline[item] = "";
                if (monthVal2) {
                  that.formInline[item] =
                    that.customFormOne[item] +
                    "," +
                    year +
                    "-" +
                    month +
                    "-" +
                    monthVal4 +
                    " " +
                    monthVal2;
                } else {
                  that.formInline[item] =
                    that.customFormOne[item] +
                    "," +
                    year +
                    "-" +
                    month +
                    "-" +
                    monthVal4;
                }
              }

              // console.log(that.formInline);
            }
          }
        }
      }
    },
    /*回调*/
    callbackFun() {
      /*自定义控件-双控件*/
      let that = this;
      if (that.customArrKey && that.customArrKey.length > 0) {
        let listArr = that.customArrKey;
        let customRuleForm = {};
        for (let item in that.formInline) {
          if (listArr.indexOf(item) !== -1) {
            let arr;
            if (typeof that.formInline[item] === "string") {
              arr = that.formInline[item].split(",");
            } else {
              arr = that.formInline[item];
            }
            customRuleForm[item + "1"] = "";
            if (arr[0] === undefined || arr[0] === null) {
              customRuleForm[item + "1"] = "";
            } else {
              customRuleForm[item + "1"] = arr[0];
            }
            customRuleForm[item + "2"] = "";
            if (arr[1] === undefined || arr[1] === null) {
              customRuleForm[item + "2"] = "";
            } else {
              customRuleForm[item + "2"] = arr[1];
            }
            /*限定范围*/
            for (let k = 0; k < that.formList.length; k++) {
              if (item === that.formList[k].name) {
                let characteristic = that.formList[k].characteristic;
                let val;
                if (arr[0] === undefined || arr[0] === null) {
                  val = "";
                } else {
                  val = arr[0];
                }
                that.DatePickerScopeChange(
                  val,
                  item,
                  characteristic,
                  that.formList[k].valueformat1,
                  that.formList[k].monitertype,
                  that.formList[k].control
                );
                break;
              }
            }
          }
        }
        that.customForm = customRuleForm;
        // console.log(that.customForm, '双控件-回调');
      }
      /*自定义控件-单控件*/
      let customRuleForm1 = {};
      let listArr1 = that.formList;
      if (listArr1) {
        for (let item in that.formInline) {
          for (let i = 0; i < listArr1.length; i++) {
            if (item == listArr1[i].name && listArr1[i].isdiadic) {
              if (typeof that.formInline[item] === "string") {
                customRuleForm1[item] = that.formInline[item].split(",")[0];
              } else {
                customRuleForm1[item] = that.formInline[item][0];
              }
            }
          }
        }
        that.customFormOne = customRuleForm1;
      }
    },
    /*年-季-改变事件*/
    yearQuarterChange(val1, val2) {
      let that = this;
      if (that.customForm[val1] && that.customForm[val1] != "") {
        if (!that.customForm[val2] || that.customForm[val2] == "") {
          that.customForm[val2] = "1";
        }
      } else {
        that.customForm[val2] = "";
      }
    },
    yearQuarterSChange(val1, val2) {
      let that = this;
      if (that.customForm[val2] && that.customForm[val2] != "") {
        if (!that.customForm[val1] || that.customForm[val1] == "") {
          that.customForm[val1] = new Date().getFullYear().toString();
        }
      } else {
        that.customForm[val1] = "";
      }
    },
    /*text按钮的点击事件*/
    textBtnClick(value, name, text) {
      let that = this;
      /*返回事件*/
      that.$emit("textBtnFun", value, name, text);
    }
  },
  mounted() {
    const that = this;
    // let refs = {};
    /*回调*/
    that.callbackFun();
    /*监听*/
    that.BUS.$on("formInline", function (formInline) {
      if (that.customArrKey && that.customArrKey.length > 0) {
        for (let item in formInline) {
          if (that.customArrKey.indexOf(item) !== -1) {
            if (formInline[item] !== null && formInline[item] !== undefined) {
              let val1 =
                formInline[item][0] && formInline[item][0] !== "null"
                  ? formInline[item][0]
                  : "";
              let val2 =
                formInline[item][1] && formInline[item][1] !== "null"
                  ? formInline[item][1]
                  : "";
              if (that.customForm[item + 1] !== val1) {
                that.customForm[item + 1] = val1;
              }
              if (that.customForm[item + 2] !== val2) {
                that.customForm[item + 2] = val2;
              }
            }
          }
        }
      }
    });
    setTimeout(() => {
      if (that.formInline && that.listJson) {
        if (that.listJson.nodecode && that.listJson.nodecode == "basin") {
          that.formList.map(function (item, index) {
            if (item.name == "parentcode" && item.type == "treeselect") {
              item.treedata = [];
              var data = {
                code: null
              };
              that.$http
                .post(
                  that.SUPERVISION_PLATFORM_MANAGE +
                    "supervisionPlatform/basinInfo/getAllBasinInfoByCode",
                  data
                )
                .then(function (success) {
                  var dataList = success.data;
                  if (dataList.flag == "success") {
                    item.treedata = dataList.data;
                  }
                });
              item.treedata.map(function (it, id) {
                if (it.children && it.children.length > 0) {
                  it.children = null;
                }
              });
            }
          });
        }
      }
    }, 3000);
  },
  watch: {
    customForm: {
      handler(curVal, oldVal) {
        let that = this;
        if (that.customArrKey && that.customArrKey.length > 0) {
          let listArr1 = that.customArrKey;
          that.customModelFun(that.formInline, listArr1);
        }
      },
      deep: true
    },
    formList: {
      handler(curVal, oldVal) {
        let that = this;
        /*双控件*/
        let listArr = that.customArrKey;
        let customRuleForm = {};
        if (listArr) {
          for (let item in that.formInline) {
            if (listArr.indexOf(item) != -1) {
              let arr;
              customRuleForm[item + "1"] = "";
              if (typeof that.formInline[item] === "string") {
                arr = that.formInline[item].split(",");
              } else {
                arr = that.formInline[item];
              }
              if (arr[0] === undefined || arr[0] === null) {
                customRuleForm[item + "1"] = "";
              } else {
                customRuleForm[item + "1"] = arr[0];
              }
              customRuleForm[item + "2"] = "";
              if (arr[1] === undefined || arr[1] === null) {
                customRuleForm[item + "2"] = "";
              } else {
                customRuleForm[item + "2"] = arr[1];
              }
              /*限定范围*/
              for (let k = 0; k < that.formList.length; k++) {
                if (item === that.formList[k].name) {
                  let characteristic = that.formList[k].characteristic;
                  let val;
                  if (arr[0] === undefined || arr[0] === null) {
                    val = "";
                  } else {
                    val = arr[0];
                  }
                  that.DatePickerScopeChange(
                    val,
                    item,
                    characteristic,
                    that.formList[k].valueformat1,
                    that.formList[k].monitertype,
                    that.formList[k].control
                  );
                  break;
                }
              }
            }
          }
          that.customForm = customRuleForm;
        }
        /*单控件*/
        let customRuleForm1 = {};
        let listArr1 = that.formList;
        if (listArr1) {
          for (let item in that.formInline) {
            for (let i = 0; i < listArr1.length; i++) {
              if (item == listArr1[i].name && listArr1[i].isdiadic) {
                if (typeof that.formInline[item] === "string") {
                  customRuleForm1[item] = that.formInline[item].split(",")[0];
                } else {
                  customRuleForm1[item] = that.formInline[item][0];
                }
              }
            }
          }
          that.customFormOne = customRuleForm1;
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped>
#superForm {
  position: relative;
}

.el-form-item__label * {
  display: inline-block !important;
}
#superForm > div {
  float: left;
  min-height: 38px;
  width: 300px; /*默认宽度*/
  min-width: 200px; /*最小宽度*/
}

#superForm .tree_select {
  padding-top: 2px;
}

#superForm .form_list {
  padding-top: 0;
}
</style>
<style lang="less" scoped>
.paragraph {
  // /deep/.el-form-item__label {
  //   padding-right: 20px;
  // }
  .paragraph_box {
    position: relative;
    padding-right: 24px;
    .paragraph_icon {
      display: inline-block;
      width: 5px;
      height: 16px;
      background: #2085ef;
      border-radius: 10px;
      position: absolute;
      left: 0;
      top: 3px;
    }
    .paragraph_label {
      color: #000;
      font-weight: 700;
      display: inline-block;
      padding-left: 10px;
    }
  }
  // /deep/.el-form-item__label::before {
  //   content: "";
  //   height: 24px;
  //   width: 12px;
  //   background: #2085ef;
  //   border-radius: 5px;
  //   position: relative;
  // }
}
.uploadBtn {
  margin-left: 10px;
}
.allowUploadBtn {
  color: #3267e2;
  cursor: pointer;
}
.notUploadBtn {
  color: #a9a9a9;
  cursor: not-allowed;
}
.upload {
  width: 15%;
  background: #ecebfb;
  display: inline-block;
  padding: 0 3px;
  border-radius: 4px;
}
</style>
<style>
.el-color-picker--mini .el-color-picker__trigger {
  vertical-align: middle;
}
.el-select-dropdown {
  width: 160px !important;
}
#superForm .vue-treeselect__single-value,
#superForm .vue-treeselect__placeholder {
  font-size: 13px;
}
.el-scrollbar__wrap::-webkit-scrollbar {
  display: none;
}

.el-scrollbar__wrap {
  width: 100%;
  -ms-overflow-style: none !important;
  overflow: auto !important;
  overflow: -moz-scrollbars-none !important;
  margin-bottom: 0 !important;
}

.el-select-dropdown__item {
  overflow: inherit;
}

.vue-treeselect__value-container {
  height: auto !important;
  display: inherit !important;
  top: -8px;
}

.vue-treeselect__multi-value {
  position: absolute;
  top: 8px;
}

#superForm .input_t .el-input__inner {
  padding-right: 8px;
  padding-left: 12px;
}

#superForm .data_time .el-input__inner {
  padding-right: 8px;
}

#superForm .el-radio-group,
#superForm .el-checkbox-group {
  display: block;
}

#superForm .el-radio,
#superForm .el-checkbox {
  margin-left: 0;
  margin-right: 30px;
  line-height: 33px;
}

#superForm .el-radio-group .el-radio:last-child {
  margin-right: 0;
}

#superForm .el-checkbox-group .el-checkbox:last-child {
  margin-right: 0;
}
/* 单选多选可以改变颜色 */
#SuperTable.noTableborder .el-table .el-radio__inner,
#SuperTable.noTableborder .el-table .el-checkbox__inner {
  border-color: #dddddd !important;
}
#superForm .myCheck .el-checkbox {
  margin-right: 4px;
  padding: 3px 8px !important;
  line-height: 24px;
}
#superForm .myCheck .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}
#superForm .myCheck .el-checkbox__input {
  display: none;
}
#superForm .myCheck .el-checkbox__label {
  padding-left: 0;
}
.delInput input::-webkit-outer-spin-button,
.delInput input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
.delInput input[type="number"] {
  -moz-appearance: textfield !important;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.myDisabled .el-input.is-disabled .el-input__inner{
  /* background: pink; */
}
.myReadonly input[readonly="true"]{
  background-color: #F5F7FA;
  border-color: #E4E7ED;
  color: #C0C4CC;
}
.myReadonly input[readonly="readonly"]{
  background-color: #F5F7FA;
  border-color: #E4E7ED;
  color: #C0C4CC;
}
</style>
